// @import url('https://fonts.googleapis.com/css2?family=Hubballi&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hubballi&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
html {
    scroll-behavior: smooth;
}

body {
    font-size: 14px;
    font-family: barlow, sans-serif;
    overflow-x: hidden;
}

.container {
    max-width: 1350px;
}

button {
    span {
        font-size: 14px;
    }
}

button:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.font-bold {
    font-weight: 800;
}

.vw-height {
    height: 100vh;
}

.note-editor {
    border-radius: 0.25rem;

    .note-icon-caret::before {
        content: "";
    }
}

.text-sm {
    font-size: 14px;
}

.text-lg {
    font-size: 18px;
}

.bg-orange {
    background-color: #FFA552;
}

.bg-black {
    background-color: #000000;
}

.bg-lightblue {
    background-color: #90D4F9;
}

.bg-green {
    background-color: #16c568;
}

.bg-red {
    background-color: #dc3545;
}

.bg-gray {
    background-color: #F8F7F7;
}

.bg-darkgray {
    background-color: #D3D3D3;
}

.bg-dark {
    background-color: #17191a
}

.ui-nav-bg {
    background-color: #F8F7F7;
}

.border-gray {
    border: 1px solid #D2D2D2;
}

.border-orange {
    border: 1px solid #FFA552;
}

.text-black {
    color: #000000;
}

.text-gray {
    color: #D3D3D3;
}

.text-orange {
    color: #FFA552;
}

.text-danger {
    color: #dc3545
}

.underline {
    text-decoration: underline;
}

.cursor-default {
    cursor: default;
}

.pointer {
    cursor: pointer;
}

.button-fw {
    font-weight: 600;
}

.letter-0 {
    letter-spacing: 0;
}

.letter-1 {
    letter-spacing: .5px;
}

.letter-2 {
    letter-spacing: 2px;
}

.gap-05 {
    gap: 0.5rem;
}

.btn-orange {
    background-color: #FFA552;
    font-weight: bolder;
}

.btn-black {
    background-color: #000000;
    font-weight: bolder;
}

.img-cover {
    object-fit: cover;
}

.no-reserve:hover,
#coin:hover {
    cursor: default;
}

.img-size {
    width: 100%;
    height: auto;
}

.transparent-logo {
    opacity: 0.4;
}

.pagination {
    padding-top: 32px;

    .page-item {
        height: 34px;
        min-width: 34px;
        margin: 0 3px;
        border-radius: 4px;

        &.disable {
            pointer-events: none;

            .page-link {
                opacity: .4;
            }
        }

        &.active {
            background-color: #FFA552;
            border-color: transparent;

            &:hover {
                background-color: #FFA552;
                opacity: 1;
            }

            .page-link {
                background-color: #FFA552;
            }
        }

        &:first-child,
        &:last-child {
            .page-link {
                background-color: white;
            }

        }

        &:hover {
            opacity: .7;
        }

        .page-link {
            font-weight: 600;
            font-size: 15px;
            border: none;
            background-color: #F8F7F7;
        }
    }
}

.conversation-body-admin {
    max-height: 600px;
    height: 600px;
    min-height: 600px;
    overflow-y: auto;
}

.bg-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    width: 65%;
}

.avatar {
    .plus {
        display: none;
    }
}

.fa-star {
    cursor: pointer;
}

.webkit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.car-div-color:nth-child(odd) {
    background: #FAFAFA;
}

.section-anchor {
    position: absolute;
    margin-top: -100px;
    left: 0;
}

#notifications-div {
    max-height: 400px;
    overflow-y: auto;
}

.btn:focus {
    box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.nav-link {
    cursor: pointer;
}

.categories-pills,
.categories-subpills  {
    gap: .5rem 0;

    .nav-link {
        font-size: 14px;
        border: 1px solid #dee2e6;
        color: #6c757d;

        &.active {
        background-color: #F8F7F7;
        color: black;
        font-weight: bolder;

            h6 {
                font-weight: 600;
            }
        }

        h6 {
            text-transform: uppercase;
        }
    }
}

.time-bar-wrapper {
    margin-bottom: -5px;
    z-index:11;
}

.time-bar {
    height: 5px;
}

.auction-country-flag {
    width:22px;
    object-fit:cover;
}

.link-icon {
    transition: .3s;

    &:hover {
        opacity: .5;
    }
}

.categories-subpills {

    .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: #F8F7F7;
        color: black;
        font-weight: bolder;
    }

    a:hover {
        color: black;
    }

    .active {
        h4 {
            color: black;
        }
    }

    h4 {
        color: gray;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 15px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E2E2E2;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 20px;
    border-radius: .25rem;
    background-color: #B9B9B9;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider:before {
    background-color: #FFA552;
}

input:focus+.slider {
    box-shadow: 0 0 1px #FFA552;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.profile-noti {
    input:checked+.slider:before {
        background-color: #3C9CE6;
    }
}

.rotate {
    -moz-transition: transform .5s ease;
    -webkit-transition: transform .5s ease;
    transition: transform .5s ease;
    transform: rotate(0);
}

.rotate.down {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
}

.password-group {
    input {
        background-image: none !important;
    }
}

.tooltip-inner {
    background-color: #FAFAFA;
    box-shadow: 0px 0px 4px black;
    font-weight: bold;
    opacity: 1 !important;
    color: black;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #FAFAFA !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #FAFAFA !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #FAFAFA !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #FAFAFA !important;
}

.collapsing {
    transition: none !important;
}

.form-control:focus {
    box-shadow: none;
}

.sticky-top {
    z-index: 998;
}

.rotate-180 {
    transform: rotate(180deg);
}

.cursor-pointer {
    cursor: pointer;
}

// .goog-te-banner-frame {
//     display: none;
//     height: 0 !important;
//     visibility: hidden
// }

// #google_translate_element {
//     display: none;
// }

*[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media(max-width: 767px) {
    .ui-nav {
        h4 {
            font-size: 14px;
        }
    }

    .ui-parking-nav {
        h4 {
            font-size: 14px;
        }
    }

    .profile-noti {
        h5 {
            font-size: 16px;
        }
    }
}

@media(max-width: 350px) {
    .ui-parking-nav {
        h4 {
            font-size: 10px;
        }
    }
}

.text-sm {
    font-size: 14px;
}

.avatar-lg {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.avatar-sm {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.auction-actions {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.thumbnail-image {
    transition: opacity .6s ease-in-out, height .6s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
}
.thumbnail-image.active {
    opacity: 1;
    height: 190px;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px solid #dee2e6 !important;
}
